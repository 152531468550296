class ModalesProyectos{
    constructor(scope){
        this.tabs = Array.from(scope.find('[data-modal]'))
        this.init()
        
    }

    init(){
        this.tabs.forEach(tab => tab.addEventListener('click', this.abrirModal.bind(this)))
    }

    abrirModal(e){
        Scrollbar.destroyAll()
        const modal = document.getElementById(e.currentTarget.dataset.modal)
        const contenido = modal.querySelector('.section-modal-content')
        const btn_cerrar = modal.querySelector('.cerrar-modal')
        btn_cerrar.addEventListener('click', ()=> {
            modal.classList.add('cerrar')
        })
        modal.addEventListener('animationend', (e)=>{
            if (e.animationName == 'cerrar-modal') {
				modal.classList.remove('cerrar')
				modal.close()
                Scrollbar.initAll()
                
			}
        })
        modal.addEventListener('cancel', e => {
            e.preventDefault()
            modal.classList.add('cerrar')
        })
        modal.showModal()
        contenido.scrollTo(0,0)

    }
}