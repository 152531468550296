class Acordion {
    constructor(target) {
        this.acordion = target
        this.summary = target.querySelector('summary');
        this.content = target.querySelector('.contenido');
        this.acordion.height = 0
        this.tabs = target.querySelectorAll('.tab')
        this.contents = Array.from(target.querySelectorAll('.tab-content'))
        this.init()
    }
    
    init(){
        this.summary.addEventListener('click', this.toggle.bind(this))
        this.tabs.forEach(tab => {
            tab.addEventListener('click', this.activarTab.bind(this))
        });
    }
  
    toggle(e){
        e.preventDefault()
        if (this.acordion.open) {
            this.cerrar()
        }else{
            this.abrir()
        }
    }
    cerrar(){
        const altura = this.content.offsetHeight
        const animacion = this.content.animate(
            {height:[`${altura}px`, '0']},
            {duration: 500, easing: 'ease'})
        animacion.onfinish = ()=> {
            this.acordion.open = false
        }
    }

    abrir(){
        const event = new Event('open');
        this.acordion.open = true
        this.acordion.dispatchEvent(event);
        const altura = this.content.offsetHeight
        this.content.animate(
            {height:['0', `${altura}px`]},
            {duration: 500, easing: 'ease'}
            )
    }

    activarTab(e){
        const target = e.target
        const nombre_contenido = target.dataset.tab
        this.tabs.forEach(tab => tab.classList.remove('active'))
        target.classList.add('active')
        this.contents.map(cont => {
            if (cont.dataset.content === nombre_contenido){
                cont.classList.add('show')
            }else{
                cont.classList.remove('show')
            }
        })
    }
  }