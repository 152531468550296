class BodyColor {
  constructor() {
    this._bodyColor();
  }

  _bodyColor() {
    const pageWrapper = document.querySelector('.page-wrapper__content');
    const header = document.querySelector('.header');
    
    if (pageWrapper && header) {
      // Get all classes from page-wrapper__content
      const classes = pageWrapper.className.split(' ');
      // Find the bg-* class
      const bgClass = classes.find(cls => cls.startsWith('bg-'));
      
      if (bgClass) {
        // Update the header's sticky theme
        header.setAttribute('data-arts-header-sticky-theme', bgClass);
      }
    }
  }
}

// Initialize the class when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
  new BodyColor();
});