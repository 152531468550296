class Tiras{
	constructor(scope){
		this.carriles = scope.find('.carril')
		this.tl = gsap.timeline({
			defaults:{
				ease: 'none'
			},
			scrollTrigger: {
				trigger: ".tiras",
				
			  start: "top bottom", // when the top of the trigger hits the top of the viewport
			  end: "bottom top", // end after scrolling 500px beyond the start
			  scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
			}
		});
		this.init()
	}

	init(){
		this.tl.to('.carril.drive', {x: -500})
		this.tl.fromTo('.carril.reverse', {x: -500},{x: 0}, 0)


	}
}