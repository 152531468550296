class Random {
    constructor() {
        this._randomjs();
      
    }

    _randomjs() {

		var collection = $(".random").get();
                  collection.sort(function() {
                      return Math.random()*10 > 5 ? 1 : -1;
                  });
                  $.each(collection,function(i,el) {
                      var color = this.className,
                          $el = $(el);
                  $el.css({}).appendTo( $el.parent() );
          }); 

       


    }
    
  
}