class filtroEstudios{
    constructor(scope){
        this.selects = Array.from(scope.find('.filtro'))
        this.todos = Array.from(scope.find('.estudio'))
        this.filtro = ''
        this.init()
    }

    init(){
        this.selects.forEach(select => {
            select.addEventListener('change', this.hacerFiltro.bind(this))
        })
    }

    hacerFiltro(){
        const valorInicial = '';
        this.filtro = this.selects.reduce(
            (previousValue, currentValue) => previousValue + currentValue.value,
            valorInicial
        );
        this.filtrar()
    }

    filtrar(){
        const state = Flip.getState(this.todos);
        if (this.filtro) {
            this.todos.forEach( e => {
                e.classList.remove('d-flex')
                e.style.display = 'none'
            })
            document.querySelectorAll(this.filtro).forEach( e => {
                e.style.display = ''
                e.classList.add('d-flex')
            })
        }else{
            this.todos.forEach( e => {
                e.classList.add('d-flex')
                e.display = ''
            })
        }
        Flip.from(state, {
            ease: "sine.inOut",
            // absolute: true,
            onEnter: (elements) => {
             return gsap.from(elements, {
              yPercent: 100,
              opacity: 0,
              ease: "sine.out"
             });
            },
            onLeave: (elements) => {
             return gsap.to(elements, {
              scale: 0.3,
              rotation: 360,
              opacity: 0,
              ease: "sine.out"
             });
            }
           });


    }
}