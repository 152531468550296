class ModalEcosistema{
    constructor(target){
        this.elemento = target
        this.wraper = target.querySelector('.modal-wraper-contenido')
        this.contenidos = Array.from(this.wraper.querySelectorAll('.modal-contenido'))
        this.contenidosToShow = []
        this.btn_cerrar = target.querySelector('.cerrar-modal')
        this.btn_prev = target.querySelector('.modal-nav-prev')
        this.btn_next = target.querySelector('.modal-nav-next')
        this.actual = 0
        this.prev = 0
        this.next = 0
        this.init()
    }

    init(){
        this.navegar(0)
        this.btn_cerrar.addEventListener('click', this.cerrar.bind(this))
        this.btn_prev.addEventListener('click', ()=> this.navegar(this.actual - 1))
        this.btn_next.addEventListener('click', ()=> this.navegar(this.actual + 1))
        this.elemento.addEventListener('animationend', (e)=>{
			if (e.animationName == 'cerrar-modal') {
				this.elemento.classList.remove('cerrar')
				this.elemento.close()
			}
		})
        this.elemento.addEventListener('cancel', e => {
            e.preventDefault()
            this.elemento.classList.add('cerrar')
        })
    }

    cerrar(){
        Scrollbar.initAll()
        this.elemento.classList.add('cerrar')
        this.contenidosToShow.forEach(el => el.classList.remove('show'))
    }

    inRango(numero){
        return numero < 0 ? this.contenidosToShow.length -1 : numero % this.contenidosToShow.length
    }

    navegar(pos){
        if (this.contenidosToShow.length) {
            this.contenidosToShow[this.actual].classList.remove('show')
            this.actual = this.inRango(pos)
            this.prev = this.inRango(this.actual - 1)
            this.next = this.inRango(this.actual + 1)
            this.contenidosToShow[this.actual].classList.add('show')
            this.setTitulosNav(
                this.contenidosToShow[this.prev].dataset.title,
                this.contenidosToShow[this.next].dataset.title
            )
        }
    }

    setTitulosNav(prev, next){
        this.btn_next.innerText = next
        this.btn_prev.innerText = prev
    }
    openModal(target){
        
        Scrollbar.destroyAll()
        const elementos = []
        Array.from(target.parentElement.children).forEach(e => elementos.push(e.dataset.id))
        this.contenidosToShow = this.contenidos.filter(cont => {return elementos.includes(cont.id)})
        console.log(this.contenidosToShow);
        this.contenidosToShow.map((c, i) => {
            if (c.id == target.dataset.id) {
                this.navegar(i)
            }
        })
        this.elemento.showModal()
    }
    
}