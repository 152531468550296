class Kareoke{
    constructor(nodo){
        this.nodo = nodo
        this.init()
    }

    init(){
        const nodo = this.nodo,
            range = new Range(),
            selection = document.getSelection(),
            animacion = {nodo: nodo.childNodes[0].childNodes[0], length: 0},
            tl = gsap.timeline({
                defaults: {ease: "none"},
                scrollTrigger:{
                    trigger: nodo,
                    start: "top 70%",
                },
                onUpdate: ()=> {
                    range.setEnd(animacion.nodo, animacion.length);
                    selection.addRange(range)
                }
            });
            selection.removeAllRanges()
            tl
            .set(animacion, {nodo:nodo.childNodes[0].childNodes[0]})
            .to(animacion, {length: 15, duration:0.3})
            .set(animacion, {nodo:nodo.childNodes[0].childNodes[1].childNodes[0], length: 0})
            .to(animacion, {length: 11, duration:.3})
            .set(animacion, {nodo:nodo.childNodes[0].childNodes[2], length: 0})
            .to(animacion, {length: 88, duration:3})
            .set(animacion, {nodo:nodo.childNodes[1].childNodes[0], length: 0})
            .to(animacion, {length: 172, duration:5});
        
        range.setStart(nodo.firstChild.firstChild, 0);
        console.log(selection);
        
        // ScrollTrigger.create({
        //     animation: tl,
        //     trigger: nodo,
        //     start: 'top 50%',
            
        //     onUpdate: ()=> {
        //         range.setStart(nodo.firstChild,0);
        //         range.setEnd(nodo.lastChild, animacion.child1);
        //         selection.addRange(range);
        //     }
        // })
        // ScrollTrigger.create({
        //     trigger: nodo,
        //     start: "0% 70%",
        //     end: "100% 70%",
        //     onUpdate: self => {
        //         range.setStart(nodo.firstChild,1);
        //         range.setEnd(nodo.lastChild,286 * self.progress);
        //         selection.addRange(range);
        //     }
        //   });
    }
}